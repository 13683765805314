import CustomBarChart from "./barChart";
import GridItem from "./gridItem";
import CustomMatrixChart from "./matrixChart";
import CustomNumberChart from "./numberChart";
import CustomPieChart from "./pieChart";
import CustomTableChart from "./tableChart";

export interface ChartItem {
  label: string;
  chart_type: "number" | "pie" | "bar" | "table";
  value: number;
  previous_value: number;
  data: { name: string; value: number }[];
  previous_data: { name: string; value: number }[];
  table_data?: {
    headings: string[];
    values: string[][];
  };
  matrix_data: { start: string; end: string }[];
  dataLabel: string;
  start_date: string;
  end_date: string;
}

export default function GridItemWrapper(props: ChartItem) {
  if (props.chart_type === "number") {
    return (
      <GridItem
        size="small"
        element={
          <CustomNumberChart
            color="black"
            data={props.value}
            previous_data={props.previous_value}
          />
        }
        label={props.label}
      />
    );
  }
  if (props.chart_type === "pie") {
    return (
      <GridItem
        size="large"
        element={<CustomPieChart data={props.data} />}
        label={props.label}
      />
    );
  }
  if (props.chart_type === "bar") {
    return (
      <GridItem
        size="large"
        element={
          <CustomBarChart
            data={props.data}
            label={props.dataLabel}
            previous_data={props.previous_data}
          />
        }
        label={props.label}
      />
    );
  }
  if (props.chart_type === "table") {
    const dataLength = props.table_data?.values
      ? props.table_data?.values.length
      : 0;
    return (
      <GridItem
        size={dataLength > 3 ? "large" : "small"}
        element={<CustomTableChart data={props.table_data} />}
        label={props.label}
      />
    );
  }
  if (props.chart_type === "matrix") {
    return (
      <GridItem
        size="x-large"
        element={
          <CustomMatrixChart
            data={props.matrix_data}
            label={props.label}
            start_date={props.start_date}
            end_date={props.end_date}
          />
        }
        label={props.label}
      />
    );
  }
  return <></>;
}
