import {
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { color } from "chart.js/helpers";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import { eachHourOfInterval, getISODay } from "date-fns";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  MatrixController,
  MatrixElement,
  LinearScale,
  Colors,
  Title,
  Tooltip,
  Legend
);

export const Matrix = (props: any) => <Chart type="matrix" {...props} />;

const weekday = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export interface CustomMatrixChartProps {
  data: { start: string; end: string }[];
  start_date: string;
  end_date: string;
  label: string;
}

function generateData(props: CustomMatrixChartProps) {
  const formatted_data = props.data.map((x) => ({
    start: new Date(x.start + "Z"),
    end: new Date(x.end + "Z"),
  }));
  const matrix_data = [];
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 24; j++) {
      matrix_data.push({
        x: j,
        y: weekday[i],
        v: 0,
        d: i,
      });
    }
  }
  for (const d of formatted_data) {
    const hour_intervals = eachHourOfInterval({ start: d.start, end: d.end });
    let counter = 0;
    for (const h of hour_intervals) {
      counter++;
      if (d.end.getMinutes() === 0 && counter === hour_intervals.length) {
        // Skipping the last interval
        continue;
      }
      const start_weekday = getISODay(h) - 1;
      const found = matrix_data.findIndex(
        (f) => f.d === start_weekday && f.x === h.getHours()
      );
      if (found >= 0) {
        matrix_data[found].v += 1;
      }
    }
  }
  return matrix_data;
}

export default function CustomMatrixChart(props: CustomMatrixChartProps) {
  const xLabels = [];
  for (let j = 0; j < 24; j++) {
    xLabels.push(j);
  }
  const scales = {
    x: {
      type: "category",
      labels: xLabels,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      type: "category",
      labels: weekday,
      ticks: {
        maxRotation: 0,
        autoSkip: false,
        display: true,
      },
      grid: {
        display: false,
        drawBorder: false,
        tickLength: 0,
      },
    },
  };
  const data = {
    datasets: [
      {
        label: props.label,
        data: generateData(props),
        borderWidth: 1,
        backgroundColor({ raw }: any) {
          const alpha = raw.v === 0 ? 0 : (10 + raw.v) / 60;
          return color("blue").alpha(alpha).rgbString();
        },
        borderColor({ raw }: any) {
          const alpha = (10 + raw.v) / 60;
          return color("blue").alpha(alpha).darken(0.3).rgbString();
        },
        width: ({ chart }: any) =>
          (chart.chartArea || {}).width / chart.scales.x.ticks.length - 3,
        height: ({ chart }: any) =>
          (chart.chartArea || {}).height / chart.scales.y.ticks.length - 3,
      },
    ],
  };
  return (
    <div className="relative h-full w-full place-content-center grid justify-self-center self-center mt-2">
      <Matrix
        options={{
          scales,
          plugins: {
            legend: false,
            tooltip: {
              displayColors: false,
              callbacks: {
                title() {
                  return "";
                },
                label(context: any) {
                  const v = context.dataset.data[context.dataIndex];
                  const hour = v.x < 12 ? `${v.x} AM` : `${v.x - 12} PM`;
                  return ["Hour - " + hour, "Meetings - " + v.v];
                },
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
