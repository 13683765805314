import React from "react";
import { apiURL, fetchData } from "../lib/api";

interface User {
  id: string;
  email: string;
  is_superuser: boolean;
}

const AuthContext = React.createContext({
  loading: false,
  isLoggedIn: false,
  isSuperUser: false,
  user: undefined,
  login: () => {},
  logout: () => {},
} as { loading: boolean; isSuperUser: boolean; user: User | undefined; isLoggedIn: boolean; login: any; logout: any });

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<User | undefined>();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isSuperUser, setIsSuperUser] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = React.useState<boolean>(true);

  React.useEffect(() => {
    fetchData<User>({ url: apiURL.auth.getCurrentUser, method: "GET" })
      .then((user) => {
        if (user.id) {
          setUser(user);
          setIsLoggedIn(true);
          setIsSuperUser(user.is_superuser);
        }
      })
      .catch((_error) => {})
      .finally(() => setLoadingInitial(false));
  }, []);

  const login = () => {
    setLoading(true);
    fetchData<{ authorization_url: Location }>({
      url: apiURL.auth.login,
      method: "GET",
    })
      .then((data) => {
        window.location = data.authorization_url;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = () => {
    setLoading(true);
    fetchData({ url: apiURL.auth.logout, method: "POST" })
      .then(() => {
        setIsLoggedIn(false);
        setUser(undefined);
        // @ts-ignore
        window.location = "/";
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const memoedValue = React.useMemo(
    () => ({
      isLoggedIn,
      isSuperUser,
      user,
      loading,
      logout,
      login,
    }),
    [user, isLoggedIn, loading, isSuperUser]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {loadingInitial && <p>Loading...</p>}
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
