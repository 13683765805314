import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { routeLinks } from "../lib/routeLinks";
import LoginButton from "./auth/loginButton";
import LogoutButton from "./auth/logoutButton";

export default function Header() {
  const { isLoggedIn, login, logout, loading, isSuperUser } = useAuth();
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams && searchParams.get("message")) {
      const message = searchParams.get("message");
      if (message) {
        setAlertMessage(message);
      }
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (alertMessage) {
      toast(alertMessage, {
        autoClose: 60 * 1000,
        position: toast.POSITION.TOP_RIGHT,
        className: "mt-20",
        toastId: btoa(alertMessage),
      });
      setAlertMessage(undefined);
      setSearchParams({ ...searchParams, message: "" });
    }
  }, [alertMessage]);

  const onSignInClicked = () => {
    login();
  };

  const onSignOutClicked = () => {
    logout();
  };

  const navigation = [
    { name: "About Us", href: routeLinks.about },
    { name: "FAQ", href: routeLinks.faq },
  ];
  return (
    <Disclosure as="nav" className="bg-headline-blue shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href={routeLinks.home} className="-m-1.5 p-1.5">
                    <span className="text-3xl font-extrabold underline underline-offset-8 text-white">
                      hdln
                    </span>
                    <sup className="text-sm pl-2 text-white align-super no-underline">
                      Beta
                    </sup>
                  </a>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {isLoggedIn ? (
                    <a
                      href={routeLinks.dashboard}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-gray-300"
                    >
                      My hdln
                    </a>
                  ) : (
                    <LoginButton className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-gray-300" />
                  )}
                  {navigation.map((n) => (
                    <a
                      key={n.name}
                      href={n.href}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-gray-300"
                    >
                      {n.name}
                    </a>
                  ))}
                  {isSuperUser ? (
                    <a
                      href={routeLinks.admin}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-gray-300"
                    >
                      Admin
                    </a>
                  ) : (
                    <></>
                  )}
                  <LogoutButton className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-gray-300" />
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {isLoggedIn ? (
                <Disclosure.Button
                  as="a"
                  href={routeLinks.dashboard}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300  hover:text-white"
                >
                  My hdln
                </Disclosure.Button>
              ) : (
                <Disclosure.Button
                  as="button"
                  onClick={() => onSignInClicked()}
                  disabled={loading}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300  hover:text-white"
                >
                  Login
                </Disclosure.Button>
              )}
              {navigation.map((n) => (
                <Disclosure.Button
                  key={n.href}
                  as="a"
                  href={n.href}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300 hover:text-white"
                >
                  {n.name}
                </Disclosure.Button>
              ))}
              {isSuperUser ? (
                <Disclosure.Button
                  as="a"
                  href={routeLinks.admin}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300  hover:text-white"
                >
                  Admin
                </Disclosure.Button>
              ) : (
                <></>
              )}
              {isLoggedIn ? (
                <Disclosure.Button
                  as="button"
                  onClick={() => onSignOutClicked()}
                  disabled={loading}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300  hover:text-white"
                >
                  Logout
                </Disclosure.Button>
              ) : (
                <></>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
