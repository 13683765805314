import { useAuth } from "../../hooks/useAuth";

interface LoginButtonProps {
  className?: string;
}

export default function LoginButton(props: LoginButtonProps) {
  const { loading, login } = useAuth();

  const onSignInClicked = () => {
    login();
  };

  return (
    <button onClick={() => onSignInClicked()} disabled={loading} {...props}>
      Login
    </button>
  );
}
