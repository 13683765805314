import { format } from "date-fns";

export interface CustomTableChartProps {
  data?: {
    headings: string[];
    values: string[][];
  };
}

export default function CustomTableChart(props: CustomTableChartProps) {
  if (!props.data) {
    return <></>;
  }
  const timeIndex = props.data.headings.indexOf("Time");
  return (
    <div className="mt-10 mx-2 self-center">
      <table className="table-auto border border-gray-300 w-full">
        <thead>
          <tr>
            {props.data.headings.map((h, i) => {
              return (
                <th
                  className="border border-gray-300"
                  key={`table-heading-${i}`}
                >
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm">
          {props.data.values.map((row, j) => {
            return (
              <tr key={`table-row-${j}`}>
                {row.map((r, k) => {
                  let value = r;
                  if (k === timeIndex) {
                    const d = new Date(value + "Z");
                    value = format(d, "hh:mm a");
                  }
                  return (
                    <td
                      className="border border-gray-300 text-center"
                      key={`table-row-cell-${j}-${k}`}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
