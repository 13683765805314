export interface GridItemProps {
  size: "small" | "large" | "x-large";
  element: React.ReactElement;
  label: string;
}

export default function GridItem(props: GridItemProps) {
  const innerClassName = `relative h-64 ${
    props.size === "large"
      ? "md:h-96"
      : props.size === "x-large"
      ? "md:h-96"
      : "md:h-64"
  } w-full md:block mb-0 md:mb-3 md:ml-0 md:mr-6 border-4 border-gray-300 rounded-lg transform group-hover:translate-x-0 group-hover:shadow group-hover:translate-y-0 transition duration-700 ease-out overflow-hidden`;
  return (
    <div className="cursor-pointer inline-block w-full group">
      <div className={innerClassName}>
        <div className="absolute w-full h-full object-cover rounded-lg transform group-hover:scale-105 transition duration-700 ease-out cursor-pointer grid">
          {props.element}
        </div>
        <div className="font-semibold	text-center	mt-2">{props.label}</div>
      </div>
    </div>
  );
}
