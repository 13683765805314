import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useAuth } from "../../hooks/useAuth";
import { GA4MeasurementId } from "../../lib/ga4";

interface GoogleAnalyticsProps {
  page: string;
  title: string;
  description?: string;
}

export default function GoogleAnalytics(props: GoogleAnalyticsProps) {
  const { user } = useAuth();

  ReactGA.initialize([
    {
      trackingId: GA4MeasurementId,
      gaOptions: {
        userId: user?.id,
      },
    },
  ]);
  ReactGA.send({ hitType: "pageview", page: props.page, title: props.title });
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        {props.description && (
          <meta name="description" content={props.description} />
        )}
      </Helmet>
    </>
  );
}
