import { Suspense, lazy } from "react";
import ReactGA from "react-ga4";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Footer from "./components/footer";
import Header from "./components/header";
import {
  AdminProtectedRoute,
  ProtectedRoute,
} from "./components/protectedRoute";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { routeLinks } from "./lib/routeLinks";
import AdminPage from "./pages/admin";

const AboutUsPage = lazy(() => import("./pages/about"));
const FaqPage = lazy(() => import("./pages/faq"));
const HomePage = lazy(() => import("./pages/index"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const PrivacyPolicyPage = lazy(() => import("./pages/privacyPolicy"));
const TermsPage = lazy(() => import("./pages/termsOfUse"));

function App() {
  const { user } = useAuth();
  if (user) {
    ReactGA.set({ userId: user.id });
  }
  return (
    <Suspense fallback={<h2>Loading...</h2>}>
      <Router>
        <>
          <ToastContainer />
          <Header />
          <Routes>
            <Route path={routeLinks.home} element={<HomePage />} />
            <Route path={routeLinks.about} element={<AboutUsPage />} />
            <Route path={routeLinks.faq} element={<FaqPage />} />
            <Route
              path={routeLinks.privacyPolicy}
              element={<PrivacyPolicyPage />}
            />
            <Route path={routeLinks.termsOfUse} element={<TermsPage />} />
            <Route
              path={routeLinks.dashboard}
              element={
                <ProtectedRoute>
                  <DashboardPage key={"dashboard-page"} />
                </ProtectedRoute>
              }
            />
            <Route
              path={routeLinks.admin}
              element={
                <AdminProtectedRoute>
                  <AdminPage key={"admin-page"} />
                </AdminProtectedRoute>
              }
            />
          </Routes>
          <Footer />
        </>
      </Router>
    </Suspense>
  );
}

const AppWithProvider = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWithProvider;
