import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { routeLinks } from "../lib/routeLinks";

export const ProtectedRoute = ({ children }: any) => {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate to={routeLinks.home} replace={true} />;
  }
  return children;
};

export const AdminProtectedRoute = ({ children }: any) => {
  const { isLoggedIn, isSuperUser } = useAuth();
  if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate to={routeLinks.home} replace={true} />;
  }

  if (!isSuperUser) {
    // user is not authorized
    return <Navigate to={routeLinks.home} replace={true} />;
  }
  return children;
};
