import useFetch from "../../hooks/useFetch";
import { apiURL } from "../../lib/api";
import GridContainer from "../visualisation/gridContainer";
import GridItemWrapper, { ChartItem } from "../visualisation/gridItemWrapper";

export default function AdminDashboard() {
  const { isLoading, apiData } = useFetch<ChartItem[]>(
    apiURL.admin.charts,
    "GET"
  );

  if (isLoading) {
    return (
      <button type="button" className="bg-indigo-500" disabled>
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg>
        Loading...
      </button>
    );
  }

  if (apiData && apiData.length > 0) {
    return (
      <div className="bg-headline-white h-full my-20">
        <h2 className="text-5xl md:text-7xl text-center mb-12 text-slate-600">
          Admin Stats
        </h2>
        <GridContainer>
          {apiData?.map((a) => (
            <GridItemWrapper {...a} />
          ))}
        </GridContainer>
      </div>
    );
  }
  return <></>;
}
