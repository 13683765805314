import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/20/solid";
export interface CustomNumberChartProps {
  data: number;
  previous_data?: number;
  color: "black" | "red";
}

function CustomNumberChartDifference(props: CustomNumberChartProps) {
  if (props.data && props.previous_data) {
    const changePercentage =
      ((props.data - props.previous_data) / props.previous_data) * 100;
    if (changePercentage > 0) {
      return (
        <div className="text-base place-self-end pt-8 flex">
          <span>
            <ArrowLongUpIcon className="h-6 fill-green-500" />
          </span>
          <span>{Math.round(Math.abs(changePercentage))}% since last week</span>
        </div>
      );
    }
    if (changePercentage < 0) {
      return (
        <div className="text-base place-self-end pt-8 flex">
          <span>
            <ArrowLongDownIcon className="h-6 fill-red-500" />
          </span>
          <span>{Math.round(Math.abs(changePercentage))}% since last week</span>
        </div>
      );
    }
    if (changePercentage === 0) {
      return (
        <div className="text-base place-self-end pt-8 flex">
          <span>
            <ArrowsUpDownIcon className="h-6 fill-yellow-500" />
          </span>
          <span>{Math.round(Math.abs(changePercentage))}% since last week</span>
        </div>
      );
    }
  }

  return (
    <div className="text-base place-self-end pt-8 flex">
      <span>
        <ArrowsUpDownIcon className="h-6 fill-yellow-500" />
      </span>
      <span>{Math.round(Math.abs(0))}% since last week</span>
    </div>
  );
}

export default function CustomNumberChart(props: CustomNumberChartProps) {
  const className = `grid place-content-center text-8xl text-center ${
    props.color === "black" ? "text-black" : "text-red-800"
  }`;

  return (
    <div className={className}>
      <p>{props.data}</p>
      <CustomNumberChartDifference {...props} />
    </div>
  );
}
