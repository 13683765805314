import { useAuth } from "../hooks/useAuth";
import { routeLinks } from "../lib/routeLinks";

export default function Footer() {
  const { isLoggedIn } = useAuth();

  return (
    <footer className="bg-indigo-950" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8 xl:items-baseline">
          <div className="space-y-4 pt-12 md:pt-0">
            <a href={routeLinks.home}>
              <span className="text-3xl font-extrabold underline underline-offset-8 text-white">
                hdln
              </span>
              <sup className="text-sm pl-2 text-white align-super no-underline">
                Beta
              </sup>
            </a>
          </div>
          <div className="grid  gap-8 ">
            <div className="md:grid md:grid-cols-3 md:gap-6 xl:mt-0 pt-16 sm:pt-24">
              {isLoggedIn && (
                <div>
                  <a href={routeLinks.dashboard}>
                    <h3 className="text-xl font-normal leading-6 text-slate-300">
                      My hdln
                    </h3>
                  </a>
                </div>
              )}
              {!isLoggedIn && <></>}
              <div className="mt-10 md:mt-0">
                <a href={routeLinks.about}>
                  <h3 className="text-xl font-normal leading-6 text-slate-300">
                    About Us
                  </h3>
                </a>
              </div>
              <div className="mt-10 md:mt-0">
                <a href={routeLinks.faq}>
                  <h3 className="text-xl font-normal leading-6 text-slate-300">
                    FAQ
                  </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-auto pt-4">
            <img
              className="inline-block object-cover h-32 w-32"
              src={routeLinks.images + "/footer_made_in_ny.png"}
              alt=""
            />
          </div>
        </div>
        <div className="mt-8 border-t border-slate-600 pt-8">
          <div className="flex flex-row justify-between">
            <p className="text-md leading-5 text-slate-400">HDLN, inc.</p>
            <p className="text-md leading-5 text-slate-400">
              Contact: hello@hldn.io
            </p>
            <div>
              <p className="text-md leading-5 text-slate-400">
                <a className="mr-4 xl:mr-2" href={routeLinks.privacyPolicy}>
                  Privacy Policy
                </a>
                <a href={routeLinks.termsOfUse}>Terms of Use</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
