import { useAuth } from "../../hooks/useAuth";

interface LogoutButtonProps {
  className?: string;
}

export default function LogoutButton(props: LogoutButtonProps) {
  const { loading, isLoggedIn, logout } = useAuth();

  const onLogoutClicked = () => {
    logout();
  };

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <button onClick={() => onLogoutClicked()} disabled={loading} {...props}>
      Logout
    </button>
  );
}
