import {
  ArcElement,
  Chart as ChartJS,
  Colors,
  Legend,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Colors, Tooltip, Legend);

export interface CustomPieChartProps {
  data: Array<{ name: string; value: number }>;
}

export default function CustomPieChart(props: CustomPieChartProps) {
  const data = {
    labels: props.data.map((d) => d.name),
    datasets: [
      {
        data: props.data.map((d) => d.value),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="relative h-5/6 w-5/6 place-content-center grid justify-self-center self-center mt-4 md:mt-2">
      <Pie
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            colors: {
              enabled: true,
              forceOverride: true,
            },
          },
        }}
      />
    </div>
  );
}
