export const routeLinks = {
  home: "/",
  about: "/about-us",
  faq: "/faq",
  dashboard: "/dashboard",
  admin: "/admin",
  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  images: `${process.env.PUBLIC_URL}/assets/images`,
};
