import AdminDashboard from "../components/adminpage/adminDashboard";
import GoogleAnalytics from "../components/analytics/googleAnalytics";
import { routeLinks } from "../lib/routeLinks";

export default function AdminPage() {
  return (
    <>
      <AdminDashboard />
      <GoogleAnalytics page={routeLinks.admin} title="Admin" />
    </>
  );
}
