const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export interface FetchDataInput {
  url: string;
  method: "POST" | "GET" | "DELETE";
  options?: RequestInit;
}

export const apiURL = {
  auth: {
    login: `${API_BASE_URL}/auth/google/authorize`,
    getCurrentUser: `${API_BASE_URL}/users/me`,
    logout: `${API_BASE_URL}/auth/jwt/logout`,
  },
  dashboard: {
    charts: `${API_BASE_URL}/engine/dashboard/charts`,
    availableWeeks: `${API_BASE_URL}/engine/dashboard/availableWeeks`,
  },
  admin: {
    charts: `${API_BASE_URL}/admin/dashboard/charts`,
  },
  credentials: {
    all: `${API_BASE_URL}/credentials`,
    byName: (creds: string) => `${API_BASE_URL}/credentials/${creds}`,
  },
  reconnect: (creds: string) =>
    `${API_BASE_URL}/oauth2/authorize/${creds}?timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
  subscription: (provider: string) =>
    `${API_BASE_URL}/subscriptions/${provider}`,
};

export async function fetchData<T>(input: FetchDataInput) {
  const resp = await fetch(input.url, {
    credentials: "include",
    method: input.method,
    ...input.options,
  });
  if (resp.status === 204) {
    return resp.body as T;
  }
  const data: T = await resp.json();
  return data;
}
