import { useEffect, useState } from "react";
import { fetchData } from "../lib/api";

function useFetch<T>(
  url: string,
  method: "POST" | "GET",
  options?: RequestInit
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<T | null>(null);
  const [serverError, setServerError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchMethod = async () => {
      setIsLoading(true);
      try {
        const data = await fetchData<T>({ url, method, options });
        setApiData(data);
        setIsLoading(false);
      } catch (error: unknown) {
        setServerError(error as Error);
        setIsLoading(false);
      }
    };
    fetchMethod();
  }, [url, method, options]);

  return { isLoading, apiData, serverError };
}

export default useFetch;
