export default function GridContainer({ children }: any) {
  return (
    <div className="relative isolate overflow-hidden">
      <div className="mx-4 md:mx-40">
        <div className="lg:columns-3 md:columns-2 columns-1 gap-2">
          {children}
        </div>
      </div>
    </div>
  );
}
