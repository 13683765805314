import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { color } from "chart.js/helpers";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
  Title,
  Tooltip,
  Legend
);
export interface CustomBarChartProps {
  data: Array<{ name: string; value: number }>;
  previous_data?: Array<{ name: string; value: number }>;
  label: string;
}

export default function CustomBarChart(props: CustomBarChartProps) {
  const data = {
    labels: props.data.map((d) => d.name),
    datasets: [
      {
        label: props.label,
        data: props.data.map((d) => d.value),
        backgroundColor: color("blue").alpha(0.5).rgbString(),
      },
    ],
  };
  if (props.previous_data) {
    data.datasets.push({
      label: `${props.label} - Previous Week`,
      data: props.previous_data.map((d) => d.value),
      backgroundColor: color("red").alpha(0.5).rgbString(),
    });
  }
  return (
    <div className="relative h-full w-full place-content-center grid justify-self-center self-center mt-2">
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
